import React from "react";
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import Client from './Client';
import Admin from './Admin';
import Login from './adminComponents/Login';

const apiUrl = {
  '192.168.1.109':    'http://192.168.1.109/pizza/api/',
  'localhost':        'http://localhost/pizza/api/',
  'pizza.yagur.site': 'https://pizza.yagur.site/api/',
  'pizza.benkol.com': 'http://pizza.benkol.com/api/'
}[window.location.hostname];

var isAuthenticated = false;

function loggedIn() {
  isAuthenticated = true;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isAuthenticated
      ? <Component {...props} apiUrl={apiUrl}  />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)


const AppRouter = () => (
  <Router>
    <span>

      <Route
        path="/"
        exact
        render={(props) =>
          <Client {...props} apiUrl={apiUrl} />
        }
      />

      <Route
        path="/login"
        render={(props) =>
          <Login {...props} redirect="/admin" loggedIn={loggedIn} apiUrl={apiUrl} />
        }
      />

      <PrivateRoute
        path="/admin"
        component={Admin}
      />

    </span>
  </Router>
);

export default AppRouter;