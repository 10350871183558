import React, { Component } from 'react';

class ProductAmountSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.addAmount = this.addAmount.bind(this);
    this.substractAmount = this.substractAmount.bind(this);
    this.runCallback = this.runCallback.bind(this);
  }
  
  render() {
    return (
      <div className="product-amount-selector well">
        <a href="#" onClick={this.addAmount} className="product-amount-selector-name">{this.props.name}</a>
        <button
          type="button"
          className="btn3d btn btn-default btn-lg product-amount-selector-button product-amount-selector-add"
          onClick={this.addAmount}
        >
          <i className="fa fa-plus"></i>
        </button>
        <input
          type="number"
          min="0"
          max={this.props.slot_limit}
          value={this.state.amount}
          onChange={this.handleChange}
          className="form-control form-control-lg product-amount-selector-amount"
        />
        <button
          type="button"
          onClick={this.substractAmount}
          className="btn3d btn btn-default btn-lg product-amount-selector-button product-amount-selector-remove"
        >
          <i className="fa fa-minus"></i>
        </button>
      </div>
    );
  }

  handleChange(e) {
    const amount = parseInt(e.target.value, 10);
    this.setState({ amount });
    this.runCallback(amount);
  }

  addAmount(e) {
    e.preventDefault();
    var limit = parseInt(this.props.slot_limit, 10)
    var amount = (this.state.amount > limit - 1) ? limit : this.state.amount + 1;
    this.setState(state => ({ amount }));
    this.runCallback(amount);
  }

  substractAmount(e) {
    e.preventDefault();
    var amount = (this.state.amount < 1) ? 0 : this.state.amount - 1;
    this.setState(state => ({ amount }));
    this.runCallback(amount);
  }

  runCallback(amount) {
    this.props.callback(amount, this.props.id);
  }
}

export default ProductAmountSelector;
