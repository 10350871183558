import React, { Component } from 'react';
import SubSlot from './SubSlot';
import OrderDetails from './OrderDetails';

class Slot extends Component {
  constructor(props) {
    super(props);
    this.orderDetailsRef = React.createRef();
    this.state = {
    };

    this.fillSlot = this.fillSlot.bind(this);
    this.orderClicked = this.orderClicked.bind(this);
    this.showOrder = this.showOrder.bind(this);
    this.hideOrder = this.hideOrder.bind(this);
    this.orderHidden = this.orderHidden.bind(this);
    this.toggleAddOrderForm = this.toggleAddOrderForm.bind(this);
    this.toggleLockSlot = this.toggleLockSlot.bind(this);

  }
  
  render() {
    const {slot, orders, selectedOrderId, products, managePermanentOrders, locked} = this.props;

    var filledSubSlots = this.fillSlot(orders, slot.subSlots);

    var timeFrom = new Date();
    timeFrom.setHours(slot.h);
    timeFrom.setMinutes(slot.m - 10);

    return (
      <li className={`slot ${(timeFrom.getHours() % 2)? 'odd-hour-slot' : 'even-hour-slot'}`}>
        <div className="slot-time">
          <div className="slot-time-from">{("0" + timeFrom.getHours()).slice(-2)}:{("0" + timeFrom.getMinutes()).slice(-2)}</div>
          <div className="slot-time-to">{("0" + slot.h).slice(-2)}:{("0" + slot.m).slice(-2)}</div>
        </div>
        <div className="subSlots-list-container">
          <ul className="subSlots-list">
            {filledSubSlots.map((subSlot, key) =>
              <SubSlot key={key} subSlot={subSlot} orderClicked={this.orderClicked} selectedOrderId={selectedOrderId} />
            )}
          </ul>
          <OrderDetails
            ref={this.orderDetailsRef}
            products={products}
            orderHidden={this.orderHidden}
            deleteOrder={this.props.deleteOrder}
            getOrders={this.props.getOrders}
            apiUrl={this.props.apiUrl}
            managePermanentOrders={managePermanentOrders}
            h={slot.h}
            m={slot.m}
          />
        </div>
        <button type="button" className="add-order btn3d btn btn-primary" onClick={this.toggleAddOrderForm}><i className="fa fa-plus"></i></button>
        <button type="button" className={`lock-slot btn3d btn ${(locked)?'btn-danger-low':'btn-default'}`}  onClick={this.toggleLockSlot}><i className={`fa ${(locked)?'fa-lock':'fa-lock-open'}`}></i></button>
      </li>
    );
  }

  fillSlot(orders, subSlots) {
    let _subSlots = [...subSlots];
    if (!orders) return _subSlots;
    // sort by product size - big first
    orders.sort(function(a, b){
      return parseInt(a.product.size) - parseInt(b.product.size);
    });
    var currentSize;
    var currentOrder = 0;
    // loop on all subslots

    for (var i = 0; i < _subSlots.length; i++) {
      _subSlots[i] = [];
      if (orders[currentOrder]) {
        currentSize = parseInt(orders[currentOrder].product.size);
        while (
          orders[currentOrder] &&
          _subSlots[i].length < parseInt(orders[currentOrder].product.size) &&
          currentSize === parseInt(orders[currentOrder].product.size)
        ) {
          _subSlots[i].push(orders[currentOrder]);
          currentOrder++;
        }
      }
    }
    return _subSlots;
  }

  orderClicked(order) {
    if (this.props.selectedOrderId === order.orderId) {
      this.hideOrder();
    } else {
      this.showOrder(order);
    }
  }

  showOrder(order) {
    this.props.orderSelected(order.orderId);
    this.orderDetailsRef.current.show(order);
  }

  hideOrder() {
    this.props.orderSelected(null);
    this.orderDetailsRef.current.hide();
  }

  orderHidden() {
    this.props.orderSelected(null);
  }

  toggleAddOrderForm() {
    const {h, m} = this.props.slot;
    var today = new Date();
    const dd = ("0" + today.getDate()).slice(-2);
    const mm = ("0" + (today.getMonth() + 1)).slice(-2);
    const yyyy = today.getFullYear();
    const time = `${yyyy}-${mm}-${dd} ${h}:${m}:00`;

    this.props.orderSelected(null);
    this.orderDetailsRef.current.show(null, time);
  }

  toggleLockSlot() {
    const {slot, locked} = this.props;
    const {h, m} = slot;
    this.props.updateLockedSlots(h, m, !locked);
  }
}

export default Slot;
