import React, { Component } from 'react';

class Order extends Component {
  constructor(props) {
    super(props);

    this.orderRef = React.createRef();

    this.state = {
    };

    this.orderClicked = this.orderClicked.bind(this);
  }
  
  render() {
    const {order, selectedOrderId} = this.props;

    var inlineStyle = {
      flexBasis: 100 / parseInt(order.product.size) + '%'
    }
    const fullname = order.client.name;
    const firstName = fullname.substr(0,fullname.indexOf(' '));
    const lastName = fullname.substr(fullname.indexOf(' ')+1);
    const displayName = {
      '4': (lastName.length > 4) ? lastName.substring(0, 4) + '\'' : lastName,
      '2': firstName.charAt(0) + '. ' + lastName,
      '1': fullname
    }[order.product.size];

    const iconUri = '/images/' + order.product.icon;

    return (
        <li className={`order order-size-${order.product.size}${(order.noted)?' noted':' unnoted'}`} style={inlineStyle} ref={this.props.setRef}>
          <a href="#" className={`order-button btn3d btn btn-default-low${(order.orderId === selectedOrderId)?' active':''}`} onClick={this.orderClicked}>
            <div className="order-icon"><img src={iconUri} /></div>
            <div className="order-clientName">{displayName}</div>
          </a>
        </li>
    );
  }

  orderClicked(e) {
    if (e) {e.preventDefault()}
    const order = this.props.order;
    this.props.orderClicked(order);
  }
}

export default Order;
