import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      error : ""
    };
  }

  handleChange = event => {
    const {name, value} = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    const {username, password} = this.state;

    axios.get(`${this.props.apiUrl}auth/?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`)
    .then(result => {
      if (result.data) {
        this.props.loggedIn();
        this.props.history.push(this.props.redirect);
      } else {
        this.setState({
          error: "שם משתמש או סיסמה שגויים"
        });
      }
    },
    (error) => {
      this.setState({
        error
      });
      console.log(error);
    });
  }

  render() {
    const {username, password, error} = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <label>
            שם משתמש:
            <input type="text" name="username" value={this.state.username} onChange={this.handleChange} />
          </label>
        </div>
        <div>
          <label>
            סיסמה:
            <input type="password" name="password" value={this.state.password} onChange={this.handleChange} />
          </label>
        </div>
        <div>
          <button type="submit">כניסה</button>
        </div>
        <div>
          {error}
        </div>
      </form>
    );
  }
}

export default withRouter(Login);