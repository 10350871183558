import React, { Component } from 'react';

class Topping extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.handleChange = this.handleChange.bind(this);

  }
  
  render() {
    const {topping, checked, i, h, m} = this.props;
    const className = `topping${(checked)? ' selected' : ''}`;

    return (
      <div className={className}>
        <input type="checkbox" id={`${i}-${topping.replace(" ","-")}-${h}-${m}`} name={this.props.m} onChange={this.handleChange} checked={checked} />
        <label htmlFor={`${i}-${topping.replace(" ","-")}-${h}-${m}`} className="btn3d btn btn-default-low">{topping}</label>
      </div>
    );
  }

  handleChange(e){
    let checked = e.target.checked;
    this.props.callback(checked, this.props.topping);
  }
}

export default Topping;
