import React, { Component } from 'react';
import ProductAmountSelector from './clientComponents/ProductAmountSelector';
import ProductToppingsSelector from './clientComponents/ProductToppingsSelector';
import Screen from './clientComponents/Screen';
import axios from 'axios';
import './Client.css';
import './3dButtons.css';
import './ClientAmounts.css';
import './ClientTime.css';

//#fe4a49 • #2ab7ca • #fed766 • #e6e6ea • #f4f4f8

class Client extends Component {
  constructor(props) {
    
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      settings: [],
      products: [],
      orders: [],
      gotOrdersResponse: false,
      currentScreen: 1,
      hasOrder: false,
      takziv: '',
      clientName: '',
      comment: '',
      slots: [],
      slotsLength: 0,
      timeSelected: false,
      time: {h:null, m:null},
      currentOrders: [],
      currentOrdersConfirmation: []
    };

    this.productsAmount = [];

    this.amountChanged = this.amountChanged.bind(this);
    this.screenChanged = this.screenChanged.bind(this);
    this.takzivChanged = this.takzivChanged.bind(this);
    this.clientNameChanged = this.clientNameChanged.bind(this);
    this.commentChanged = this.commentChanged.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.slideHour = this.slideHour.bind(this);
    this.isSlotAvailable = this.isSlotAvailable.bind(this);
    this.selectTime = this.selectTime.bind(this);
    this.updateCurrentOrders = this.updateCurrentOrders.bind(this);
    this.postAllOrders = this.postAllOrders.bind(this);
    this.postOrder = this.postOrder.bind(this);
    this.handleToppingChange = this.handleToppingChange.bind(this);
    this.finishOrder = this.finishOrder.bind(this);
    this.waitForKeyboadHide = this.waitForKeyboadHide.bind(this);
    this.getAllOrders = this.getAllOrders.bind(this);
    this.getOrder = this.getOrder.bind(this);

    this.timeSelectionScreen = React.createRef();

    //this.numberOfScreens = 0;
  }

  componentDidMount() {
    axios.get(`${this.props.apiUrl}settings`)
    .then(result => {

      let settings = result.data;

      // handle locked slots
      var today = new Date();
      const dd = ("0" + today.getDate()).slice(-2);
      const mm = ("0" + (today.getMonth() + 1)).slice(-2);
      const yyyy = today.getFullYear();
      if (!settings.lockedSlots || !settings.lockedSlots.date || settings.lockedSlots.date !== `${yyyy}-${mm}-${dd}`) {
        settings.lockedSlots = {};
        settings.lockedSlots.date = `${yyyy}-${mm}-${dd}`;
        settings.lockedSlots.slots = {};
      }

      this.setState({
        settings: settings
      });
    },
    (error) => {
      this.setState({
        error
      });
    });
    
    axios.get(`${this.props.apiUrl}products`)
      .then(result => {

        const products = [];
        result.data.forEach((product) => {
          products[product.id] = product;
        });
        products.forEach((product) => {
          this.productsAmount[product.id] = 0;
        });
        this.setState({
          isLoaded: true,
          products
        });

      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      });

      window.addEventListener('load', this.handleLoad);
  }

  handleLoad() {
    //this.numberOfScreens = document.getElementById("screens-wraper").childElementCount;
  }

  render() {
    const { error, isLoaded, products, takziv, clientName, comment, currentScreen, settings,
      gotOrdersResponse, hasOrder, slots, slotsLength, timeSelected, time, currentOrders, currentOrdersConfirmation } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>טוען...</div>;
    } else {

      const today = new Date();
      const dd = ("0" + today.getDate()).slice(-2);
      const mm = ("0" + (today.getMonth() + 1)).slice(-2);
      const yyyy = today.getFullYear();
      const todayFormated = `${yyyy}-${mm}-${dd}`;
      if (todayFormated !== settings.startDay && window.location.hostname !== 'pizza.benkol.com') {
        return (
          <div id="client">
            <ul id="screens-wraper">
              <Screen color="#fe4a49" id={1} canContinue={false} canGoBack={false}>
                <h2>לא ניתן להזמין</h2>
                <p className="instructions">
                  או שעדיין לא נפתחה האפשרות לבצע הזמנות להיום או שהיא כבר נסגרה, אנא נסו שוב מאוחר יותר
                </p>
              </Screen>
            </ul>
          </div>
        );
      }

      if (currentScreen == 2) {

        if (!gotOrdersResponse) {
          const today = new Date();
          today.setMinutes(today.getMinutes() + 30);
          const currentH = today.getHours();
          const currentM = today.getMinutes()
          const dd = ("0" + today.getDate()).slice(-2);
          const mm = ("0" + (today.getMonth() + 1)).slice(-2);
          const yyyy = today.getFullYear();
          const date_day = `${yyyy}-${mm}-${dd}`;
          axios.get(`${this.props.apiUrl}orders/byDate?date_day=${date_day}`)
          .then(result => {
            const orders = result.data;

            var newSlotsLength = 0;

            const startHour = parseInt(settings.startTime.split(":")[0]);
            const endHour   = parseInt(  settings.endTime.split(":")[0]);

            for (var h = startHour; h < endHour; h++) {
              slots[h] = [];
              newSlotsLength++;
              for (var m = 0; m < 60; m = m + settings.timeInterval) {
                
                let isInTime = (currentH < h);
                if (currentH === h && currentM < m) isInTime = true;

                let isLocked = settings.lockedSlots.slots[`${h}:${m}`];
                
                if (window.location.hostname !== 'pizza.yagur.site') isInTime = true;
                
                slots[h].push({
                  minutes : m,
                  available: (this.isSlotAvailable(h, m, orders) && isInTime && !isLocked)
                });
              }
            }

            this.setState({
              gotOrdersResponse: true,
              orders,
              slots,
              slotsLength: newSlotsLength
            });

          },
          (error) => {
            this.setState({
              gotOrdersResponse: true,
              error
            });
          });
        }

      }

      if (currentScreen == 5) {

      }

      const timeSelectorHourWidth = (window.screen.availWidth > 800) ? 900 : 360;

      return (
        <div id="client">

          <ul id="screens-wraper">

            <Screen color="#fe4a49" id={1} canContinue={hasOrder} canGoBack={false} screenChanged={this.screenChanged}>
              <h2>בחרו כמויות</h2>
              <p className="instructions">
                לחצו על הפלוס או המינוס על מנת להוסיף או להסיר מוצר מההזמנה ולאחר מכן לחצו "לשלב הבא" בתחתית המסך
              </p>
              <ul id="products-amount-list">
                {products.map(product =>
                  <li key={product.id}>
                    <ProductAmountSelector name={product.name} id={product.id} slot_limit={product.slot_limit} callback={this.amountChanged} />
                  </li>
                )}
              </ul>
            </Screen>

            <Screen color="#2ab7ca" id={2} canContinue={timeSelected} canGoBack={true} screenChanged={this.screenChanged} ref={this.timeSelectionScreen}>
              <h2>בחרו שעה</h2>
              <p className="instructions">
                לחצו על השעה שבה תרצו שההזמנה תהיה מוכנה, ניתן לגלול לצדדים
              </p>
              <div id="time-selector">
                <ul id="time-selector-hours">
                  {slots.map((slotMs, slotH) =>
                    <li key={slotH} className="btn3d btn btn-default btn-lg time-selector-hour" onClick={()=>this.slideHour(slots, slotH, timeSelectorHourWidth)}>
                      {slotH}:00
                    </li>
                  )}
                </ul>
                <div id="time-selector-slider-container">
                  <ul id="time-selector-slider" className="well" style={{width:slotsLength*timeSelectorHourWidth}}>
                    {slots.map((slotMs, slotH) =>
                      <li key={slotH} className="time-selector-slider-hour" id={`time-selector-slider-hour-${slotH}`}>
                        <span className="time-selector-slider-hour-title">{slotH}:00-{slotH+1}:00</span>
                        <ul className="time-selector-slider-slots">
                          {slotMs.map(slotM =>
                            <li key={slotM.minutes} className={`time-selector-slider-slot btn3d btn btn-default-low ${(slotM.available)?"available":"not-available"}`}>
                              <input type="radio"
                                id={`${slotH}:${slotM.minutes}`}
                                name="time-selector"
                                value={`${slotH}:${slotM.minutes}`}
                                onChange={(e)=>this.selectTime(e, slotH, slotM.minutes)}
                                checked={(time.h == slotH && time.m == slotM.minutes)} 
                                disabled={(!slotM.available)}
                              />
                              <label htmlFor={`${slotH}:${slotM.minutes}`}>
                                <span className="time-selector-slider-slot-h">{slotH}:</span>
                                <span className="time-selector-slider-slot-m">{("0" + slotM.minutes).slice (-2)}</span>
                              </label>
                            </li>
                          )}
                        </ul>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Screen>

            <Screen color="#fed766" id={3} canContinue={true} canGoBack={false} screenChanged={this.screenChanged}>
              <h2>בחרו תוספות</h2>
              <p className="instructions">
                לחצו על התוספות שברצונכם להוסיף להזמנה
                <span className="mobile-only">
                    ,
                    <br/><strong>
                    במידת הצורך ניתן לגלול מטה
                    </strong>
                </span>
              </p>
              <div className="product-toppings-selector-list">
                {currentOrders.map((order, i) =>
                  <ProductToppingsSelector key={i} i={i} product={products[order.productId]} callback={this.handleToppingChange} />
                )}
              </div>
            </Screen>

            <Screen color="#fe4a49" id={4} canContinue={(takziv.length >= 3 && clientName.length >= 2)} canGoBack={true} screenChanged={this.screenChanged} nextLabel="הזמן" onClickNext={this.finishOrder} beforeNext={this.waitForKeyboadHide}>
              <h2>הקלידו פרטים</h2>
              <div id="client-details">
                <input type="text" className="form-control form-control-lg" value={takziv}     onChange={this.takzivChanged}     placeholder="מספר תקציב" />
                <input type="text" className="form-control form-control-lg" value={clientName} onChange={this.clientNameChanged} placeholder="שם מלא" />
                <textarea rows="2" className="form-control form-control-lg" value={comment}    onChange={this.commentChanged}    placeholder="הערות"></textarea>
              </div>
            </Screen>

            <Screen color="#2ab7ca" id={5} canContinue={false} canGoBack={true} screenChanged={this.screenChanged}>
              <h2>ההזמנה נקלטה</h2>
              <div id="thank-you">
                <p>נא להגיע בזמן לאסוף את הפיצה</p>
              </div>
              <div id="order-summery">
                <h2>סיכום הזמנה</h2>
                <p id="order-summery-time">{`הזמנה לשעה: ${time.h}:${(time.m) ? time.m.toString().padStart(2, '0') : ''}`}</p>
                <ul id="orders-conformation-list">
                  {currentOrdersConfirmation.map((order, i1) =>
                    <li key={i1}>
                      <h3>{order.product.product_name}</h3>
                      <ul className="orders-conformation-toppingsParts-list">
                        {order.toppings.map((part, i2) =>
                          <li key={i2} className="well">
                            <ul className="orders-conformation-toppings-list">
                              {part.map((topping, i3) =>
                                <li key={i3}>
                                  {topping}
                                </li>
                              )}
                            </ul>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}
                </ul>
              
              </div>
            </Screen>
          </ul>

        </div>
      );
    }
  }

  amountChanged(amount, id) {
    this.productsAmount[id] = amount;
    const sum = this.productsAmount.reduce((a, b) => a + b, 0);
    this.setState({ hasOrder: (sum > 0) });
    this.updateCurrentOrders(this.productsAmount);
  }

  screenChanged(screenId) {
    this.setState({ currentScreen: screenId });
  }

  takzivChanged(e) {
    this.setState({ takziv: e.target.value });
  }
  clientNameChanged(e) {
    this.setState({ clientName: e.target.value });
  }
  commentChanged(e) {
    this.setState({ comment: e.target.value });
  }

  slideHour(slots, slotH, timeSelectorHourWidth) {
    var i = 0;
    slots.forEach(function(val, _slotH){
      if (_slotH > slotH) i++;
    });
    document.getElementById('time-selector-slider-container').scrollLeft = timeSelectorHourWidth * i;
  }

  isSlotAvailable(h, m, orders) {
    let slotOrders = [...this.productsAmount];
    orders.forEach((order) => {
      var d = new Date(order.time);
      if (d.getHours() == h && d.getMinutes() == m) {
        slotOrders[order.product.id]++;
      }
    });
    return this.orderSlot(slotOrders);;
  }

  orderSlot(slotOrders) {

    const {subSlots} = this.state.settings;
    const {products} = this.state;
    var slot = [];
    var currentSubSlot = 0;
    for(var product in slotOrders) {
      if (!slotOrders[product]) {continue;};
      var amount = slotOrders[product];
      const slot_limit = products[product].slot_limit;
      if (slot_limit && slot_limit < amount) { return false; }

      while (amount > 0) {
        if (currentSubSlot >= subSlots) { return false; }

        var amountInSubSlot = (amount > products[product].size) ? products[product].size : amount;
        slot[currentSubSlot] = { product, amount : amountInSubSlot }

        amount = amount - amountInSubSlot;
        currentSubSlot++;
      }

    }

    return true;
  }

  selectTime(e, h, m) {
    e.preventDefault();
    this.setState({
      timeSelected: true,
      time: {h,m}
    });
    this.timeSelectionScreen.current.screenNext();

    this.postAllOrders(h, m, null, null, null, null, function(){
      // done
    });
  }

  postAllOrders(h, m, toppings, client_taktziv, client_name, comment, callback, i = 0) {
    var currentOrders = [...this.state.currentOrders];
    if (currentOrders.length <= i) { // exit condition
      this.setState({
        currentOrders
      });
      callback();
    } else {
      const productId = currentOrders[i].productId;
      this.postOrder(h, m, productId, toppings, client_taktziv, client_name, comment, (orderId)=>{
        currentOrders[i].orderId = parseInt(orderId, 10);
        this.postAllOrders(h, m, toppings, client_taktziv, client_name, comment, callback, i+1);
      });
    }
  }

  postOrder(h, m, product, toppings, client_taktziv, client_name, comment, callback) {

    var today = new Date();
    const dd = ("0" + today.getDate()).slice(-2);
    const mm = ("0" + (today.getMonth() + 1)).slice(-2);
    const yyyy = today.getFullYear();
    const time = `${yyyy}-${mm}-${dd} ${h}:${m}:00`;

    axios.post(`${this.props.apiUrl}order/`, {
      time,
      product,
      toppings,
      client_taktziv,
      client_name,
      comment
    }).then(result => {
        callback(result.data);
      }, (error) => {
        this.setState({
          error
        });
      });
  }
  
  updateCurrentOrders(productsAmount) {
    var currentOrders = [];
    productsAmount.map((amount, productId) => {
      for(var i = 0; i < amount; i++) {
        currentOrders.push({
          productId
        })
      }
    });
    this.setState({ currentOrders });
  }

  handleToppingChange(toppings, i) {
    var currentOrders = [...this.state.currentOrders];
    currentOrders[i].toppings = toppings;
    this.setState({
      currentOrders
    });
  }

  finishOrder() {
    this.updateAllOrders(()=>{
      this.getAllOrders(()=>{
        // done
      });
    });
  }

  updateAllOrders(callback, i = 0) {
    const currentOrders = this.state.currentOrders;
    if (currentOrders.length <= i) { // exit condition
      callback();
    } else {
      const productId = currentOrders[i].productId;
      this.updateOrder(currentOrders[i], (orderId)=>{
        this.updateAllOrders(callback, i+1);
      });
    }
  }

  updateOrder(order, callback) {
    const { takziv, clientName, comment } = this.state;
    const { h, m } = this.state.time;

    var today = new Date();
    const dd = ("0" + today.getDate()).slice(-2);
    const mm = ("0" + (today.getMonth() + 1)).slice(-2);
    const yyyy = today.getFullYear();
    const time = `${yyyy}-${mm}-${dd} ${h}:${m}:00`;


    const xhrConfig = {
      headers: {
          'X-HTTP-Method-Override': 'PUT'
      }
    }

    axios.post(`${this.props.apiUrl}order/${order.orderId}`, {
      time,
      product: order.productId,
      toppings: (order.toppings) ? order.toppings : null,
      client_taktziv: takziv,
      client_name: clientName,
      comment: comment
    }, xhrConfig).then(result => {
        callback(result.data);
      }, (error) => {
        this.setState({
          error
        });
      });
  }

  waitForKeyboadHide(callback) {
    if( /Mobi|Android/i.test(navigator.userAgent) ) {
      
      var promise = new Promise(function(resolve, reject) {
        let initalWindowHeight = window.innerHeight;
        window.addEventListener("resize", ()=>{
          if (window.innerHeight > initalWindowHeight) {
            resolve();
          }
        });
        setTimeout(function() {
          resolve();
        }, 1000);
      });

      promise.then(function(value) {
        callback();
      });

    } else {
      callback();
    }
  }


  getAllOrders(callback, i = 0) {
    const currentOrders = this.state.currentOrders;
    if (currentOrders.length <= i) { // exit condition
      callback();
    } else {
      this.getOrder(currentOrders[i].orderId, (orderId)=>{
        this.getAllOrders(callback, i+1);
      });
    }
  }

  getOrder(orderId, callback) {
    var currentOrdersConfirmation = [...this.state.currentOrdersConfirmation];

    axios.get(`${this.props.apiUrl}order/${orderId}`)
    .then(result => {
      let order = result.data;
      if (order.toppings === null) order.toppings = [];
      order.toppings.map((part, i2) => {
        return (part === null) ? [] : part;
      });
      currentOrdersConfirmation.push(order);
      this.setState({ currentOrdersConfirmation });
      if (callback) callback();
    },
    (error) => {
      this.setState({
        error
      });
    });

  }

}

export default Client;
