import React, { Component } from 'react';
import Topping from './Topping';

class ToppingsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    
    this.toppingsSelected = [];
    props.optionalToppings.map(topping => {
      this.toppingsSelected[topping] = false
    });

    this.handleChange = this.handleChange.bind(this);
  }
  
  render() {
    const { optionalToppings, i, j } = this.props;
    return (
      <div className="toppings-list">
        {optionalToppings.map(topping => 
          <Topping key={topping} id={`${i}-${j}`} topping={topping} callback={this.handleChange}/>
        )}
      </div>
    );
  }

  handleChange(checked, topping){
    this.toppingsSelected[topping] = checked;
    this.props.callback(this.toppingsSelected ,this.props.j);
  }

}

export default ToppingsList;
