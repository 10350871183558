import React, { Component } from 'react';
import Order from './Order';

class SubSlot extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  }
  
  render() {
    const {subSlot, orderClicked, selectedOrderId} = this.props;
    return (
        <li className="subSlot">
          <ul className="orders-list">
            {subSlot.map((order, key) =>
              <Order key={key} order={order} orderClicked={orderClicked} selectedOrderId={selectedOrderId} />
            )}
          </ul>
        </li>
    );
  }
}

export default SubSlot;
