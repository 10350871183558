import React, { Component } from 'react';
import Topping from './Topping';

class ToppingsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  render() {
    const { optionalToppings, selectedToppingsPart, i, h, m} = this.props;
    //if (typeof selectedToppingsPart === 'undefined') selectedToppingsPart = [];

    const selectedToppingsPartClean = selectedToppingsPart.map(function(topping) { 
        return topping.replace(/(\r\n|\n|\r)/gm, "");
    });

    return (
      <div className="toppings-list">
        {optionalToppings.map((topping) => 
          <Topping
            key={topping}
            topping={topping}
            i={i}
            callback={this.handleChange}
            checked={(selectedToppingsPartClean.indexOf(topping) !== -1)}
            h={h}
            m={m}
          />
        )}
      </div>
    );
  }

  handleChange(checked, topping) {
    let selectedToppingsPart = (this.props.selectedToppingsPart)? [...this.props.selectedToppingsPart] : [];
    const i = selectedToppingsPart.indexOf(topping);
    if (checked) {
      if (i === -1) {
        selectedToppingsPart.push(topping);
      }
    } else {
      if (i !== -1) {
        selectedToppingsPart.splice(i, 1);
      }
    }
    this.props.callback(selectedToppingsPart ,this.props.i);
  }

}

export default ToppingsList;
