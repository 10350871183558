import React, { Component } from 'react';
import ProductToppingsSelector from './ProductToppingsSelector';
import axios from 'axios';

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      orderId: null,
      time: null,
      clientName: '',
      clientTaktziv: '',
      comment: '',
      productId: null,
      toppings: [],
      noted: false,
      display: false,
      formSubmited: false
    };

    this.height = 0;

    this.containerEl = null;
    this.detailsEl = null;
    
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.delete = this.delete.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleToppingChange = this.handleToppingChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  render() {
    const {status, orderId, clientName, clientTaktziv, comment, productId, toppings, noted, formSubmited} = this.state;
    const {products, h, m} = this.props;

    var orderDetailsContent = '';
    if (status === 'update' && !orderId) {
      orderDetailsContent =
        <p>טוען...</p>;
    } else {

      const clientNameInput = 
        <label>
          שם:
          <input type="text" name="clientName" value={clientName} onChange={this.handleChange} className="form-control" />
        </label>

      const clientTaktzivInput = 
        <label>
          תקציב:
          <input type="text" name="clientTaktziv" value={clientTaktziv} onChange={this.handleChange} className="form-control" />
        </label>

      const productNameInput =
        <label>
          מוצר:
          <select name="productId" value={(productId)?productId:''} onChange={this.handleChange} className="form-control">
            {products.map((_product) => 
              <option value={_product.id} key={_product.id}>{_product.name}</option>
            )}
          </select>
        </label>

      const commentInput = 
      <label>
        הערות:
        <textarea rows="2" name="comment" value={comment} onChange={this.handleChange} className="form-control"></textarea>
      </label>

      const notedInput = 
        <label>
          <input type="checkbox" name="noted" className="" onChange={this.handleCheckboxChange} checked={noted} />
          עברה לפתק
        </label>

      const toppingsInput = (products[productId] && parseInt(products[productId].number_of_toppings) > 0) ?
        <label>
          תוספות:
          <div className="product-toppings-selector-list">
            <ProductToppingsSelector
              product={products[productId]}
              selectedToppings={toppings}
              callback={this.handleToppingChange}
              h={h}
              m={m}
            />
          </div>
        </label>
        :
        <div></div>
        ;

      var submitTitle = '';
      switch(status) {
        case 'update':
          submitTitle = (formSubmited) ? 'מעדכן...' : 'עדכון';
          break;
        case 'add':
          submitTitle = (formSubmited) ? 'שומר...' : 'שמירה';;
          break;
      }

      const delButton = (status === 'update') ?
        <button type="button" className="order-delete-button btn3d btn btn-danger" onClick={this.delete}>
          <i className="fa fa-trash"></i>
          מחיקה
        </button>
        :
        ''
      ;

      orderDetailsContent =
        <div>
          <form onSubmit={this.handleSubmit}>
            <div className="order-details-inputs-group">
              {clientNameInput}
              {clientTaktzivInput}
              {productNameInput}
              {commentInput}
              {notedInput}
            </div>
            {toppingsInput}
            <div className="order-details-buttons">
              <button
                type="submit"
                className="btn3d btn btn-success"
                disabled={formSubmited}
                >
                <i className="fa fa-save"></i>
                {submitTitle}
              </button>
              {delButton}
              <button
                type="button"
                className="order-close-button btn3d btn btn-default"
                onClick={this.hide}
                >
                <i className="fa fa-times-circle"></i>
                סגירה
              </button>
            </div>
          </form>
        </div>
    }



    return (
      <div className={`order-details-container${(this.state.display)? ' show' : ''}`} ref={ (containerEl) => this.containerEl = containerEl}>
        <div className="order-details" ref={ (detailsEl) => this.detailsEl = detailsEl}>
          {orderDetailsContent}
        </div>
      </div>
    );

  }
  handleChange (event) {
    const {name, value} = event.target;
    this.setState({
      [name]: value
    }, () => {
      this.containerEl.style.height = this.detailsEl.clientHeight + "px";
    });
  }

  handleCheckboxChange (event) {
    const {name, checked} = event.target;
    this.setState({
      [name]: checked
    });
  }

  handleToppingChange(toppings) {
    this.setState({
      toppings
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      formSubmited: true
    });
    
    const {productId, clientTaktziv, clientName, comment, toppings, noted, orderId, time} = this.state;
    const {apiUrl, managePermanentOrders} = this.props;


    const data = {
      time,
      product: productId,
      toppings,
      client_taktziv: clientTaktziv,
      client_name: clientName,
      comment: comment,
      noted: noted,
      permanent: managePermanentOrders
    }

    switch(this.state.status) {

      case 'add':
        axios.post(`${apiUrl}order/`, data).then(result => {
          this.hide();
          this.props.getOrders();
        }, (error) => { });
        break;

      case 'update':
        const xhrConfig = {
          headers: {
              'X-HTTP-Method-Override': 'PUT'
          }
        }
        //axios.put(`${apiUrl}order/${orderId}`, data).then(result => {
        axios.post(`${apiUrl}order/${orderId}`, data, xhrConfig).then(result => {
          this.hide();
          this.props.getOrders();
        }, (error) => { });
        break;
    }

  }

  show(order, time = order.time) {
    var firstProductId = null;
    for(var product of this.props.products) {
      if (product) {
        firstProductId = product.id;
        break;
      }
    }

    const state = (order) ? {
      status: 'update',
      orderId: order.orderId,
      time,
      clientName: order.client.name,
      clientTaktziv: order.client.taktziv,
      comment: order.comment,
      productId: order.product.id,
      toppings: order.toppings,
      noted: order.noted,
      display: true,
      formSubmited: false
    } : {
      status: 'add',
      orderId: null,
      time,
      clientName: '',
      clientTaktziv: '',
      comment: '',
      productId: firstProductId,
      toppings: [],
      noted: false,
      display: false,
      formSubmited: false
    };
    this.setState(state, () => {
      this.containerEl.style.height = this.detailsEl.clientHeight + "px";
    });
  }

  hide() {
    this.containerEl.style.height = "0px";
    this.setState({
      display: false
    });
    this.props.orderHidden();
  }

  delete() {
    const response = window.confirm("למחוק את ההזמנה?");
    if (response) {
      this.props.deleteOrder(this.state.orderId);
      this.hide();
    }
  }
}

export default OrderDetails;
