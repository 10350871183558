import React, { Component } from 'react';
import axios from 'axios';
import Slot from './adminComponents/Slot';
import './Admin.css';
import './3dButtons.css';

class Admin extends Component {
  constructor(props) {
      super(props);

      const d = new Date();

      this.state = {
        error: null,
        isLoaded: false,
        settings: [],
        products: [],
        orders: [],
        ordersRedindexed: [],
        slots: [],
        selectedOrderId: null,
        managePermanentOrders: false,
        chargingYear: d.getFullYear(),
        chargingMonth: d.getMonth() + 1,
        chargingDay: d.getDate(),
        isChargingWindowDisplayed: false,
        changeDateYear: d.getFullYear(),
        changeDateMonth: d.getMonth() + 1,
        changeDateDay: d.getDate(),
        isChangeDateWindowDisplayed: false,
        displayedDateYear: d.getFullYear(),
        displayedDateMonth: d.getMonth() + 1,
        displayedDateDay: d.getDate()
      };

      this.getSettings = this.getSettings.bind(this);
      this.getProducts = this.getProducts.bind(this);
      this.getOrders = this.getOrders.bind(this);
      this.deleteIncompleteOrders = this.deleteIncompleteOrders.bind(this);
      this.orderSelected = this.orderSelected.bind(this);
      this.deleteOrder = this.deleteOrder.bind(this);
      this.switchToManageRegularOrders = this.switchToManageRegularOrders.bind(this);
      this.switchToManagePermanentOrders = this.switchToManagePermanentOrders.bind(this);
      this.importPermanentOrders = this.importPermanentOrders.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.startDay = this.startDay.bind(this);
      this.endDay = this.endDay.bind(this);
      this.changeSetting = this.changeSetting.bind(this);
      this.getDayCharges = this.getDayCharges.bind(this);
      this.getMonthCharges = this.getMonthCharges.bind(this);
      this.getCharges = this.getCharges.bind(this);
      this.openChargingWindow = this.openChargingWindow.bind(this);
      this.closeChargingWindow = this.closeChargingWindow.bind(this);
      this.openChangeDateWindow = this.openChangeDateWindow.bind(this);
      this.closeChangeDateWindow = this.closeChangeDateWindow.bind(this);
      this.changeDate = this.changeDate.bind(this);
      this.updateLockedSlots = this.updateLockedSlots.bind(this);
  }

  componentDidMount() {
    this.getSettings(() => {
      this.getProducts(()  => {
        this.getOrders(()  => {
          setInterval(() => {
            this.deleteIncompleteOrders();
          }, 1000 * 120);
          setInterval(() => {
            this.getOrders();
          }, 1000 * 10);
        });
      });
    });

    window.addEventListener('load', this.handleLoad);
  }

  getSettings(callback = false) {
    axios.get(`${this.props.apiUrl}settings`)
    .then(result => {
      let settings = result.data;
      let slots = [];

      const startHour = parseInt(settings.startTime.split(":")[0]);
      const endHour   = parseInt(  settings.endTime.split(":")[0]);
      for (var h = startHour; h < endHour; h++) {
        for (var m = 0; m < 60; m = m + settings.timeInterval) {
          let subSlots = []
          for (var i = 1; i <= settings.subSlots + 2; i++) {
            subSlots.push([]);
          }
          slots.push({h, m, subSlots});
        }
      }

      // handle locked slots
      var today = new Date();
      const dd = ("0" + today.getDate()).slice(-2);
      const mm = ("0" + (today.getMonth() + 1)).slice(-2);
      const yyyy = today.getFullYear();
      if (!settings.lockedSlots || !settings.lockedSlots.date || settings.lockedSlots.date !== `${yyyy}-${mm}-${dd}`) {
        settings.lockedSlots = {};
        settings.lockedSlots.date = `${yyyy}-${mm}-${dd}`;
        settings.lockedSlots.slots = {};
      }

      this.setState({
        isLoaded: true,
        settings,
        slots
      });

      if (callback) callback();
    },
    (error) => {
      this.setState({
        isLoaded: true,
        error
      });
    });
  }

  getOrders(callback = false) {
    const {managePermanentOrders, displayedDateYear, displayedDateMonth, displayedDateDay} = this.state;

    var getUrl = '';
    if (managePermanentOrders) {
      getUrl = `${this.props.apiUrl}orders/permanent`;
    } else {

      /*const today = new Date();
      const dd = ("0" + today.getDate()).slice(-2);
      const mm = ("0" + (today.getMonth() + 1)).slice(-2);
      const yyyy = today.getFullYear();*/

      const dd = ("0" + displayedDateDay).slice(-2);
      const mm = ("0" + (displayedDateMonth)).slice(-2);
      const yyyy = displayedDateYear;

      const date_day = `${yyyy}-${mm}-${dd}`;

      getUrl = `${this.props.apiUrl}orders/byDate?date_day=${date_day}`;
    }

    axios.get(getUrl)
    .then(result => {
      const orders = result.data;
      let ordersRedindexed = [];

      orders.forEach(function(order){
        order.toppings = JSON.parse(order.toppings);
        let d = new Date(order.time);
        let h = d.getHours();
        let m = d.getMinutes();
        if (!ordersRedindexed[h])    { ordersRedindexed[h] = []; }
        if (!ordersRedindexed[h][m]) { ordersRedindexed[h][m] = []; }
        ordersRedindexed[h][m].push(order);
      });

      this.setState({
        ordersRedindexed,
        orders
      });

      if (callback) callback();
    },
    (error) => {
      this.setState({
        error
      });
    });
  }

  deleteIncompleteOrders() {
    const {managePermanentOrders, displayedDateYear, displayedDateMonth, displayedDateDay} = this.state;

    if (managePermanentOrders) { return }

    const dd = ("0" + displayedDateDay).slice(-2);
    const mm = ("0" + (displayedDateMonth)).slice(-2);
    const yyyy = displayedDateYear;

    const date_day = `${yyyy}-${mm}-${dd}`;

    let getUrl = `${this.props.apiUrl}incompleteOrders/byDate?date_day=${date_day}`;

    const xhrConfig = {
      headers: {
          'X-HTTP-Method-Override': 'DELETE'
      }
    }

    axios.post(getUrl, null, xhrConfig)
      .then(result => {
        // ---
      },
      (error) => {
        /*this.setState({
          error
        });*/
      });
  }
  
  getProducts(callback = false) {
    axios.get(`${this.props.apiUrl}products`)
    .then(result => {
      const products = [];
      result.data.forEach((product) => {
        products[product.id] = product;
      });
      this.setState({
        products
      });

      if (callback) callback();
    },
    (error) => {
        this.setState({
          error
        });
    });
  }

  handleLoad() {
  }

  render() {
      const {
        error, isLoaded, settings, slots, ordersRedindexed, selectedOrderId, products, managePermanentOrders,
        chargingYear, chargingMonth, chargingDay, isChargingWindowDisplayed,
        displayedDateYear, displayedDateMonth, displayedDateDay, isChangeDateWindowDisplayed,
        changeDateYear, changeDateMonth, changeDateDay
      } = this.state;
      
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>טוען...</div>;
      } else {

        const chargingWindowStyle = (isChargingWindowDisplayed) ? {} : {'display' : 'none'} ;

        const chargingWindow = 
          <div id="charging-window-container" style={chargingWindowStyle}>
            <div id="charging-window">
              <div className="form-inline">
                <input type="text" name="chargingYear" value={chargingYear} onChange={this.handleChange} className="form-control" />
                <input type="text" name="chargingMonth" value={chargingMonth} onChange={this.handleChange} className="form-control" />
                <input type="text" name="chargingDay" value={chargingDay} onChange={this.handleChange} className="form-control" />
                <button type="button" className="btn3d btn btn-default" onClick={this.getDayCharges}>
                  צור ליום
                </button>
                <button type="button" className="btn3d btn btn-default" onClick={this.getMonthCharges}>
                  צור לחודש
                </button>
              </div>
              <button type="button" className="btn3d btn btn-default" onClick={this.closeChargingWindow}>
                ביטול
              </button>
            </div>
          </div>

        const changeDateWindowStyle = (isChangeDateWindowDisplayed) ? {} : {'display' : 'none'} ;

        const changeDateWindow = 
          <div id="change-date-window-container" style={changeDateWindowStyle}>
            <div id="change-date-window">
              <div className="form-inline">
                <input type="text" name="changeDateYear" value={changeDateYear} onChange={this.handleChange} className="form-control" />
                <input type="text" name="changeDateMonth" value={changeDateMonth} onChange={this.handleChange} className="form-control" />
                <input type="text" name="changeDateDay" value={changeDateDay} onChange={this.handleChange} className="form-control" />
                <button type="button" className="btn3d btn btn-default" onClick={this.changeDate}>
                  שנה תאריך
                </button>
              </div>
              <button type="button" className="btn3d btn btn-default" onClick={this.closeChangeDateWindow}>
                ביטול
              </button>
            </div>
          </div>

        /*const navButtonsStyle = (window.location.hostname === 'pizza.benkol.com') ?
          {'display' : 'none'}
        :
          {}
        ;*/
        const navButtonsStyle = {};

        const today = new Date();
        const dd = ("0" + today.getDate()).slice(-2);
        const mm = ("0" + (today.getMonth() + 1)).slice(-2);
        const yyyy = today.getFullYear();
        const todayFormated = `${yyyy}-${mm}-${dd}`;
        const openDayButtonStyle = (todayFormated === settings.startDay) ?
          {'display' : 'none'}
        : {} ;
        const closeDayButtonStyle = (todayFormated !== settings.startDay) ?
        {'display' : 'none'}
        : {} ;

        const nav = (managePermanentOrders) ?
          <div id="nav">
            <h1 id="nav-title">
              ניהול הזמנות קבועות
            </h1>
            <div id="nav-buttons" style={navButtonsStyle}>
              <button
                type="button"
                className="btn3d btn btn-default btn-sm"
                onClick={this.switchToManageRegularOrders}
              >
                <i className="fa fa-chevron-circle-left"></i>
                חזרה לניהול הזמנות של היום
              </button>
            </div>
          </div>
        :
          <div id="nav">
            <h1 id="nav-title">
              {`ניהול הזמנות - ${displayedDateDay}/${displayedDateMonth}`}
            </h1>
            <div id="nav-buttons" style={navButtonsStyle}>
            
              <button
                type="button"
                className="btn3d btn btn-default btn-sm"
                onClick={this.switchToManagePermanentOrders}
              >
                <i className="fas fa-calendar-alt"></i>
                ניהול הזמנות קבועות
              </button>

              <button
                type="button"
                className="btn3d btn btn-default btn-sm"
                onClick={this.importPermanentOrders}
              >
                <i className="fa fa-file-import"></i>
                ייבא הזמנות קבועות
              </button>

              <button
                type="button"
                className="btn3d btn btn-default btn-sm"
                onClick={this.startDay}
                style={openDayButtonStyle}
              >
                <i className="fa fa-lock-open"></i>
                פתח להזמנות
              </button>

              <button
                type="button"
                className="btn3d btn btn-default btn-sm"
                onClick={this.endDay}
                style={closeDayButtonStyle}
              >
                <i className="fa fa-lock"></i>
                סגור להזמנות
              </button>

              <button
                type="button"
                className="btn3d btn btn-default btn-sm"
                onClick={this.openChargingWindow}
              >
                <i className="fa fa-shekel-sign"></i>
                צור קובץ חיובים
              </button>

              <button
                type="button"
                className="btn3d btn btn-default btn-sm"
                onClick={this.openChangeDateWindow}
              >
                <i className="fa fa-calendar-check"></i>
                שנה תאריך
              </button>

            </div>
          </div>
        ;

        return (
          <div id="admin">
            {nav}
            <ul id="slots-list">
              {slots.map((slot, key) =>
                <Slot
                  key = {key}
                  slot = {slot}
                  orders = {
                    (ordersRedindexed[slot.h] && ordersRedindexed[slot.h][slot.m])
                    ? ordersRedindexed[slot.h][slot.m]
                    : null}
                  products={products}
                  selectedOrderId = {selectedOrderId}
                  orderSelected = {this.orderSelected}
                  deleteOrder = {this.deleteOrder}
                  getOrders = {this.getOrders}
                  updateLockedSlots = {this.updateLockedSlots}
                  locked = {(typeof settings.lockedSlots.slots[`${slot.h}:${slot.m}`] !== 'undefined' && settings.lockedSlots.slots[`${slot.h}:${slot.m}`] === true)}
                  apiUrl = {this.props.apiUrl}
                  managePermanentOrders = {managePermanentOrders}
                />
              )}
            </ul>
            {chargingWindow}
            {changeDateWindow}
          </div>
        );
      }
  }

  orderSelected(selectedOrderId) {
    this.setState({selectedOrderId});
  }

    
  deleteOrder(orderId) {

    const xhrConfig = {
      headers: {
          'X-HTTP-Method-Override': 'DELETE'
      }
    }
  
    //axios.delete(`${this.props.apiUrl}order/${orderId}`)
    axios.post(`${this.props.apiUrl}order/${orderId}`, null, xhrConfig)
      .then(result => {
        this.getOrders();
      },
      (error) => {

      });
  }

  switchToManageRegularOrders() {
    this.setState({
      managePermanentOrders: false
    }, this.getOrders);
  }

  switchToManagePermanentOrders() {
    this.setState({
      managePermanentOrders: true
    }, this.getOrders);
  }

  importPermanentOrders() {
    const response = window.confirm("לייבא הזמנות קבועות?");

    if (response) {
      axios.post(`${this.props.apiUrl}importPermanentOrders/`).then(result => {
        this.getOrders();
      }, (error) => { });
    }

  }

  handleChange (event) {
    const {name, value} = event.target;
    this.setState({
      [name]: value
    });
  }

  startDay() {
    const today = new Date();
    const dd = ("0" + today.getDate()).slice(-2);
    const mm = ("0" + (today.getMonth() + 1)).slice(-2);
    const yyyy = today.getFullYear();
    const todayFormated = `${yyyy}-${mm}-${dd}`;
    this.changeSetting('startDay', todayFormated);
  }

  endDay() {
    this.changeSetting('startDay', null);
  }

  changeSetting (name, value) {
    const data = { value };
    //axios.put(...
    const xhrConfig = {
      headers: { 'X-HTTP-Method-Override': 'PUT' }
    }
    axios.post(`${this.props.apiUrl}setting/${name}`, { value }, xhrConfig).then(result => {
      var settings = Object.assign({}, this.state.settings);
      settings[name] = value;
      this.setState({ settings });
    }, (error) => { });
  }

  getDayCharges() {
    const { chargingYear, chargingMonth, chargingDay } = this.state;
    const dd = ("0" + chargingDay).slice(-2);
    const mm = ("0" + chargingMonth).slice(-2);
    const date_day = `${chargingYear}-${mm}-${dd}`;
    const queryString = `date_day=${date_day}`;
    this.getCharges(queryString);
  }

  getMonthCharges() {
    const { chargingYear, chargingMonth } = this.state;
    const mm = ("0" + chargingMonth).slice(-2);
    const date_month = `${chargingYear}-${mm}-01`;
    const queryString = `date_month=${date_month}`;
    this.getCharges(queryString);
  }

  getCharges(queryString) {
    const url = `${this.props.apiUrl}getChargesCsv.php?${queryString}`;
    var win = window.open(url, '_blank');
    win.focus();
  }

  openChargingWindow() {
    this.setState({
      isChargingWindowDisplayed: true
    });
  }

  closeChargingWindow() {
    this.setState({
      isChargingWindowDisplayed: false
    });
  }
  
  openChangeDateWindow() {
    this.setState({
      isChangeDateWindowDisplayed: true
    });
  }

  closeChangeDateWindow() {
    this.setState({
      isChangeDateWindowDisplayed: false
    });
  }

  changeDate() {
    const { changeDateYear, changeDateMonth, changeDateDay } = this.state;
    this.setState({
      displayedDateYear: changeDateYear,
      displayedDateMonth: changeDateMonth,
      displayedDateDay: changeDateDay,
      isChangeDateWindowDisplayed: false
    }, ()=>{
      this.getOrders();
    });
  }

  updateLockedSlots(h, m, locked) {
    const { settings } = this.state;

    settings.lockedSlots.slots[`${h}:${m}`] = locked;

    const xhrConfig = {
      headers: {
          'X-HTTP-Method-Override': 'PUT'
      }
    }

    axios.post(`${this.props.apiUrl}setting/lockedSlots`, {value: settings.lockedSlots}, xhrConfig).then(result => {
      this.getSettings();
    }, (error) => { });
  }
}

export default Admin;