import React, { Component } from 'react';

class Topping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };

    this.handleChange = this.handleChange.bind(this);
  }
  
  render() {
    const {topping, id} = this.props;
    return (
      <div className="topping">
        <input type="checkbox" id={`${id}-${topping.replace(" ","-")}`} onChange={this.handleChange} checked={this.state.checked} />
        <label htmlFor={`${id}-${topping.replace(" ","-")}`} className="btn3d btn btn-default-low">{topping}</label>
      </div>
    );
  }

  handleChange(e){
    let checked = e.target.checked;
    this.setState({
      checked
    });
    
    this.props.callback(checked, this.props.topping);
  }
}

export default Topping;
