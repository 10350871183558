import React, { Component } from 'react';
import ToppingsList from './ToppingsList';
import './ProductToppingsSelector.css';

class ProductToppingsSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };

    this.tempArr = this.tempArr.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.dictionary = {
      1: "שלם",
      2: "חצי",
      3: "שליש",
      4: "רבע",
      5: "חמישית",
      6: "שישית",
      7: "שביעית",
      8: "שמינית",
      9: "תשיעית",
      10: "עשירית"
    }
  }
  
  render() {
    if (!this.props.product) {
      return (
        <div className="product-toppings-selector">
        </div>
      );
    }

    const { number_of_toppings, optional_toppings } = this.props.product;
    var numberOfToppings = parseInt(number_of_toppings);
    if (!optional_toppings || numberOfToppings == 0) return (null);
    var optionalToppingsArr = optional_toppings.split("\n");
    const optionalToppingsArr = optionalToppingsArr.map(function(topping) { 
      return topping.replace(/(\r\n|\n|\r)/gm, "");
    });

    return (
      <div className="product-toppings-selector">
        <div className="toppings-lists">
          {this.tempArr(numberOfToppings).map(i =>
            <div key={i}  className="well">
              {this.dictionary[numberOfToppings]}
              <ToppingsList
                i={i}
                optionalToppings={optionalToppingsArr}
                selectedToppingsPart={(this.props.selectedToppings && this.props.selectedToppings[i]) ? this.props.selectedToppings[i]:[]}
                callback={this.handleChange}
                h={this.props.h}
                m={this.props.m}
              />
            </div>
          )}
        </div>
      </div>
    );

  }

  tempArr(length) {
    var arr = [];
    for(var i = 0; i < length; i++) {
      arr[i] = i;
    }
    return arr;
  }
  
  handleChange(selectedToppingsPart, i){
    let selectedToppings = [...this.props.selectedToppings];
    selectedToppings[i] = selectedToppingsPart;
    this.props.callback(selectedToppings);
  }
}

export default ProductToppingsSelector;
