import React, { Component } from 'react';
import ToppingsList from './ToppingsList';
import './ProductToppingsSelector.css';

class ProductToppingsSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };
    
    this.toppingsLists = [];
    this.tempArr(parseInt(this.props.product.number_of_toppings)).map(j => {
      this.toppingsLists[j] = [];
    });

    this.tempArr = this.tempArr.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.dictionary = {
      1: "שלם",
      2: "חצי",
      3: "שליש",
      4: "רבע",
      5: "חמישית",
      6: "שישית",
      7: "שביעית",
      8: "שמינית",
      9: "תשיעית",
      10: "עשירית"
    }
  }
  
  render() {
    const { name, number_of_toppings, optional_toppings } = this.props.product;
    var numberOfToppings = parseInt(number_of_toppings);
    if (!optional_toppings || numberOfToppings == 0) return (null);
    var optionalToppingsArr = optional_toppings.split("\n");
    return (
      <div className="product-toppings-selector well">
        <div className="product-toppings-selector-name">{name}</div>
        <div className="toppings-lists">
          
          {this.tempArr(numberOfToppings).map(j =>
            <div key={j}>
              <span className="toppings-lists-name">{this.dictionary[numberOfToppings]}</span>
              <ToppingsList i={this.props.i} j={j} optionalToppings={optionalToppingsArr} callback={this.handleChange} />
            </div>
          )}
        </div>
      </div>
    );

  }

  tempArr(length) {
    var arr = [];
    for(var i = 0; i < length; i++) {
      arr[i] = i;
    }
    return arr;
  }
  
  handleChange(_toppingsSelected, j){
    var toppingsSelected = [];
    for (var topping in _toppingsSelected) {
      let selected = _toppingsSelected[topping];
      if (selected) toppingsSelected.push(topping)
    }
    this.toppingsLists[j] = toppingsSelected;
    this.props.callback(this.toppingsLists, this.props.i);
  }
}

export default ProductToppingsSelector;
