import React, { Component } from 'react';
import './Screen.css';

class Screen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navButtonsOpacity: {
                next : 0,
                back : 0
            },
        };

        this.screenNext = this.screenNext.bind(this);
        this.screenBack = this.screenBack.bind(this);

        
    }

  render() {
    var style = {
        backgroundColor: this.props.color
    };
    return (
      <li className="screen" style={style} id={`screen-${this.props.id}`}>
        <a href="#" className="navigation button-back" onClick={this.screenBack} style={{opacity:(this.props.canGoBack)?1:0}}>
          <i className="fa fa-chevron-up"></i>
          {(this.props.backLabel) ? this.props.backLabel : 'לשלב הקודם'}
        </a>
        <div className="screen-inner">
          {this.props.children}
        </div>
        <a href="#" className="navigation button-next" onClick={this.screenNext} style={{opacity:(this.props.canContinue)?1:0}}>
          {(this.props.nextLabel) ? this.props.nextLabel : 'לשלב הבא'}
          <i className="fa fa-chevron-down"></i>
        </a>
      </li>
    );
  }

  screenNext(e) {
    if (e) {e.preventDefault()}
    var newId = this.props.id + 1;
    if (this.props.beforeNext) {
      this.props.beforeNext(()=>{
        window.location.hash = 'screen-' + newId;
      });
    } else {
      window.location.hash = 'screen-' + newId;
    }
    this.props.screenChanged(newId);
    if (this.props.onClickNext) this.props.onClickNext(newId);
  }

  screenBack(e) {
    if (e) {e.preventDefault()}
    var newId = this.props.id - 1;
    window.location.hash = 'screen-' + newId;
    this.props.screenChanged(newId);
    if (this.props.onClickBack) this.props.onClickBack(newId);
  }
}

export default Screen;
